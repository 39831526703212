import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import CustomInput from "../../UI/PageInput/CustomInput";
import CenteredHeading from "../../UI/headingsUI/CenteredHeading";
import SubmitButton from "../../UI/buttonUI/SubmitButton";
import GrayButton from "../../UI/buttonUI/GrayButton";
import CustomDescriptionInput from "../../UI/PageInput/CustomDescriptionInput";
import ExpenseViewDialog from "../../UI/viewDialogs/expense/ExpenseViewDialog";
import successIcon from "../../../assets/icons/successIcon.svg";

const AddLoan = ({
  sendData,
  isSucceed,
  setIsSucceed,
  loading,
  navigate,
  responseData,
  setResponseData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { name, id, loanBalance } = useParams();

  // handle clear form
  const handleClearForm = () => {
    // reset form value
    reset();
  };

  const onSubmit = (data) => {
    const formData = {
      receiver_id: id,
      user_id: 1,
      category_id: 4,
      ...data,
    };
    sendData(formData, reset);
  };
  return (
    <div className="form-container">
      <CenteredHeading title="Add Loan" />
      <form
        className="2xs:max-lg:pt-0"
        onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
      >
        <div className="flex flex-wrap">
          <div className="leftCol m-auto">
            <label htmlFor={name} className="readyOnlyLabel">
              Name
            </label>
            <p className="readyOnlyInput">{name}</p>
            <p className="currentLoan">
              Username current loan : {loanBalance} /-
            </p>
            <CustomInput
              type="number"
              name="amount"
              labelText="Amount *"
              placeholder="Enter"
              register={{
                ...register("amount", {
                  required: "Please Enter an amount",
                  min: {
                    value: 1,
                    message: "Amount should be greater than 0",
                  },
                }),
              }}
              error={errors.amount}
            />
            <CustomDescriptionInput
              name="description"
              labelText="Description *"
              register={{
                ...register("description", {
                  required: "Please Enter Description",
                }),
              }}
              error={errors.description}
            />
          </div>
        </div>
        <div className="addLoanBtnWrap">
          <p className="addWrap">
            <SubmitButton
              btnText={!loading ? "Add Loan" : "Adding..."}
              loading={loading}
              height="h-12"
            />
          </p>
          <p className="grayWrap">
            <GrayButton
              btnText="Cancel"
              width="w-40"
              onClick={handleClearForm}
            />
          </p>
        </div>
      </form>
      {isSucceed && (
        <ExpenseViewDialog
          closeDialog={() => navigate("/expense/all")}
          modalNotClose={() => setIsSucceed(true)}
          viewDetails={responseData}
          successView={true}
          message="Loan Successfully Created!"
          messageIcon={successIcon}
          setExpenseViewDetails={setResponseData}
        />
      )}
    </div>
  );
};
export default AddLoan;
