import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import CustomInput from "../../UI/PageInput/CustomInput";
import CustomDescriptionInput from "../../UI/PageInput/CustomDescriptionInput";
import CustomFileInput from "../../UI/PageInput/CustomFileInput";
import SelectedImagesBox from "./SelectedImagesBox";
import CenteredHeading from "../../UI/headingsUI/CenteredHeading";
import SubmitButton from "../../UI/buttonUI/SubmitButton";
import GrayButton from "../../UI/buttonUI/GrayButton";
import ExpenseViewDialog from "../../UI/viewDialogs/expense/ExpenseViewDialog";
import UseDialogBox from "../../../CustomHooks/UseDialogBox";
import ImageSlider from "../../UI/viewDialogs/ImageSlider";
import successIcon from "../../../assets/icons/successIcon.svg";
import { getCompressImage } from "../../../functions/getCompressImage";

const AddDeductionExpense = ({
  files,
  setFiles,
  sendData,
  isSucceed,
  loading,
  navigate,
  setIsSucceed,
  responseData,
  setResponseData,
}) => {
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const [imageSliderPics, setImageSliderPics] = useState([]);
  const [imageSliderCurrentIndex, setImageSliderCurrentIndex] = useState();
  const { name, id } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      pictures: [], // Initialize pictures as an empty array
    },
  });

  const pic = watch("pictures");

  useEffect(() => {
    getCompressImage(setFiles, pic, "pictures");
  }, [pic, setFiles]);

  const onSubmit = (data) => {
    const formData = {
      category_id: 14,
      user_id: id,
      ...data,
    };
    sendData(formData, reset);
  };

  // handle clear form
  const handleClearForm = () => {
    setFiles({});
    // reset form value
    reset();
  };

  const openImageSlider = () => {
    setIsSucceed(false);
    setShowDialogBox(true);
    setImageSliderPics(responseData.pictureList);
  };

  const closeImageSlider = () => {
    setIsSucceed(true);
    setShowDialogBox(false);
  };

  return (
    <div className="form-container ">
      <CenteredHeading title="Add Deduction" />
      <form
        className="2xs:max-lg:pt-0"
        onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
      >
        <div className="flex flex-wrap">
          <div className="leftCol">
            <label htmlFor={name} className="readyOnlyLabel">
              Name
            </label>
            <p className="readyOnlyInput">{name}</p>
            <CustomInput
              type="number"
              name="amount"
              labelText="Amount *"
              placeholder="Enter"
              register={{
                ...register("amount", {
                  required: "Please Enter an amount",
                  validate: (value) => value !== "0" || "Amount cannot be zero",
                }),
              }}
              error={errors.amount}
            />
          </div>
          <div className="rightCol">
            <>
              <CustomFileInput
                name="pictures"
                placeholder="Add Image* (jpg, png)"
                accept=".png, .jpg, image/png, image/jpeg"
                register={{
                  ...register("pictures", {
                    required: files.pictures
                      ? files.pictures?.length === 0
                        ? "Please choose a file"
                        : false
                      : "Please choose a file",
                  }),
                }}
                multiple={true}
                error={errors.pictures}
              />
              <SelectedImagesBox
                errors={errors.pictures}
                selectedImages={files.pictures}
                setFiles={setFiles}
                setValue={setValue}
                name="pictures"
              />
            </>
          </div>
        </div>
        <CustomDescriptionInput
          name="description"
          labelText="Description *"
          register={{
            ...register("description", {
              required: "Please Enter Description",
            }),
          }}
          error={errors.description}
        />
        <div className="submitWrap">
          <p className="addWrap">
            <SubmitButton
              btnText={!loading ? "Add Deduction" : "Adding..."}
              loading={loading}
              height="h-12"
            />
          </p>
          <p className="grayWrap">
            <GrayButton
              btnText="Clear"
              width="w-40"
              onClick={handleClearForm}
            />
          </p>
        </div>
      </form>
      {isSucceed && (
        <ExpenseViewDialog
          closeDialog={() => navigate("/user/all")}
          modalNotClose={() => setIsSucceed(true)}
          viewDetails={responseData}
          openImageSlider={openImageSlider}
          setImageSliderCurrentIndex={setImageSliderCurrentIndex}
          successView={true}
          message="Deduction Expense Successfully Created!"
          messageIcon={successIcon}
          setExpenseViewDetails={setResponseData}
        />
      )}
      {showDialogBox && (
        <ImageSlider
          title="Expense Photos"
          images={imageSliderPics}
          imageSliderCurrentIndex={imageSliderCurrentIndex}
          closeDialogBox={closeImageSlider}
        />
      )}
    </div>
  );
};
export default AddDeductionExpense;
