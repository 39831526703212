import React from "react";
import CustomInput from "../../UI/PageInput/CustomInput";
import SearchDropdown from "../../UI/PageInput/SearchDropdown";
import DateInput from "../../UI/PageInput/DateInput";

const PersonalInfo = ({
  userSetup,
  register,
  errors,
  setValue,
  dob,
  selectedOption,
  setSelectedOption,
  isFormClear,
}) => {
  return (
    <div className="halfCol pr-1">
      <div className="userInfoWrap">
        <h4 className="userInfoHeading">Personal Info</h4>
        <div className="px-4 py-6">
          <CustomInput
            type="text"
            name="fullName"
            labelText="Full Name"
            placeholder="Enter"
            register={{
              ...register("fullName"),
            }}
            error={errors.fullName}
          />
          <DateInput
            label="Date of birth"
            name={"dob"}
            value={dob}
            setValue={setValue}
            register={{
              ...register("dob"),
            }}
            error={errors.dob}
            isFormClear={isFormClear}
            disableCurrentAndFutureDays={true}
          />
          <div className="flex flex-wrap ">
            <div className="halfCol">
              <div className="pr-1">
                <CustomInput
                  type="number"
                  name="salary"
                  labelText="Salary"
                  placeholder="Enter"
                  register={{
                    ...register("salary", {
                      validate: (value) =>
                        value === "" ||
                        value >= 0 ||
                        "Salary can't be less than 0",
                    }),
                  }}
                  error={errors.salary}
                />
              </div>
            </div>
            <div className="halfCol">
              <div className="pl-1">
                <SearchDropdown
                  name="genderId"
                  label="Gender"
                  keyName="name"
                  id="id"
                  options={userSetup.genders}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("genderId"),
                  }}
                  error={errors.genderId}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap ">
            <div className="halfCol">
              <div className="pr-1">
                <CustomInput
                  type="text"
                  name="mobile"
                  labelText="Phone No."
                  placeholder="Enter"
                  register={{
                    ...register("mobile", {
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit phone number.",
                      },
                    }),
                  }}
                  error={errors.mobile}
                />
              </div>
            </div>
            <div className="halfCol">
              <div className="pl-1">
                <CustomInput
                  type="text"
                  name="bikeRegNumber"
                  labelText="Bike Registration Number"
                  placeholder="Enter"
                  register={{
                    ...register("bikeRegNumber"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PersonalInfo;
