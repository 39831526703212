import React from "react";
import CustomInput from "../../UI/PageInput/CustomInput";
import SearchDropdown from "../../UI/PageInput/SearchDropdown";
import DateInput from "../../UI/PageInput/DateInput";
import CustomFileInput from "../../UI/PageInput/CustomFileInput";
import SelectedImagesBox from "../../expense/add/SelectedImagesBox";
import useShowHidePassword from "../../../CustomHooks/useShowHidePassword";
import showPass from "../../../assets/icons/showpass.svg";
import hidePass from "../../../assets/icons/hidepass.svg";

const PersonalInfo = ({
  userSetup,
  register,
  errors,
  setValue,
  dob,
  selectedOption,
  setSelectedOption,
  files,
  setFiles,
  isFormClear,
}) => {
  const { showPassword, handleClickShowPassword } = useShowHidePassword();

  return (
    <div className="userInfoWrap mt-0">
      <h4 className="userInfoHeading">Personal Info</h4>
      <div className="flex flex-wrap px-4 py-6">
        <div className="halfCol">
          <div className="pr-1">
            <CustomInput
              type="text"
              name="full_name"
              labelText="Full Name *"
              placeholder="Enter"
              register={{
                ...register("full_name", {
                  required: "Please Enter full name",
                }),
              }}
              error={errors.full_name}
            />
            <CustomInput
              type="number"
              name="salary"
              labelText="Salary *"
              placeholder="Enter"
              register={{
                ...register("salary", {
                  required: "Please Enter a salary",
                  min: {
                    value: 0,
                    message: "Salary can't be less than 0",
                  },
                }),
              }}
              error={errors.salary}
            />
            <CustomInput
              type="text"
              name="email"
              labelText="Email *"
              placeholder="Enter"
              register={{
                ...register("email", {
                  required: "Please Enter a email",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Please enter a valid email",
                  },
                }),
              }}
              error={errors.email}
            />
            <SearchDropdown
              name="gender_id"
              label="Gender *"
              keyName="name"
              id="id"
              options={userSetup.genders}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              setValue={setValue}
              register={{
                ...register("gender_id", {
                  required: "Please select a gender",
                }),
              }}
              error={errors.gender_id}
            />
            <CustomFileInput
              name="profile_pic"
              placeholder="Profile Picture (jpg, png)"
              accept=".png, .jpg, image/png, image/jpeg"
              register={{
                ...register("profile_pic"),
              }}
              multiple={false}
            />
            <SelectedImagesBox
              selectedImages={files.profile_pic}
              setFiles={setFiles}
              setValue={setValue}
              name="profile_pic"
            />
          </div>
        </div>
        <div className="halfCol">
          <div className="pl-1">
            <DateInput
              label="Date of birth *"
              name={"dob"}
              value={dob}
              setValue={setValue}
              register={{
                ...register("dob", {
                  required: "Please Select date of birth",
                }),
              }}
              error={errors.dob}
              isFormClear={isFormClear}
              disableCurrentAndFutureDays={true}
            />
            <CustomInput
              type="text"
              name="mobile_number"
              labelText="Phone No. *"
              placeholder="Enter"
              register={{
                ...register("mobile_number", {
                  required: "Please Enter phone no.",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number.",
                  },
                }),
              }}
              error={errors.mobile_number}
            />
            <CustomInput
              type={showPassword.password ? "text" : "password"}
              name="password"
              labelText="Password *"
              placeholder="Enter"
              register={{
                ...register("password", {
                  required: "Please Enter a password",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                }),
              }}
              handleClick={() => handleClickShowPassword("password")}
              inputIcon={showPassword.password ? hidePass : showPass}
              imgAlt="pass"
              error={errors.password}
            />
            <CustomInput
              type="text"
              name="bike_reg_number"
              labelText="Bike Registration Number"
              placeholder="Enter"
              register={{
                ...register("bike_reg_number"),
              }}
            />
            <CustomFileInput
              name="id_proof"
              placeholder="Id Proof * (jpg, png)"
              accept=".png, .jpg, image/png, image/jpeg"
              register={{
                ...register("id_proof"),
              }}
              multiple={true}
            />
            <SelectedImagesBox
              selectedImages={files.id_proof}
              setFiles={setFiles}
              setValue={setValue}
              name="id_proof"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PersonalInfo;
